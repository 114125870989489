import { ConfigProvider } from '@cainiaofe/cn-ui';
import { componentName } from 'src/const';
import View from 'src/view';
import { store, event } from '@ice/stark-data';
// eslint-disable-next-line import/no-cycle
import { customGetMenu, coneContainerCustomShell, customArmsConfig, osNotFoundComponent, } from './custom';
const Index = ConfigProvider.config(View, {
    componentName,
});
Index.displayName = componentName;
// 自定义404界面
store.set('os-not-found-component', osNotFoundComponent);
// 自定义菜单
store.set('custom-get-menu', customGetMenu);
event.on('cone-container-on-route-change', () => { });
event.on('cone-container-on-page-rendered', () => { });
// 自定义shell
if (!location.href.includes('hideCustomShell=true')) {
    store.set('cone-container-custom-shell', coneContainerCustomShell);
}
// 自定义业务arms实例配置
store.set('custom-arms-config', customArmsConfig);
export default Index;
