/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import Index from 'src';
import { request, cnOptions, queryLoginInfo, } from './view/header/services/index.js';
import { CnBox, CnMessage } from '@cainiaofe/cn-ui';
const l1 = '/cone';
const l2 = '/next';
const boss = '/dwork/win/boss/';
// boss
const home = `${boss}home${l1}`;
const basic = `${boss}basic`;
const site = `${boss}site`;
const building = `${boss}building`;
const cost = `${boss}cost`;
const purchase = `${boss}purchase${l2}`;
const operation = `${boss}operation${l2}/page`;
const dh = `${boss}dh${l2}`;
const expand = `${boss}expand${l2}`;
// asset
const nirvana = '/dwork/win/nirvana/';
const asset = `${nirvana}asset${l2}`;
// park
const parkPlatform = `/platform${l1}`;
// vision
const vision = '/dwork/win/vision/home';
export const dworkPathnameMap = {
    // boss
    home,
    basic: `${basic}${l1}`,
    basicNext: `${basic}${l2}`,
    site: `${site}${l1}`,
    siteNext: `${site}${l2}`,
    building: `${building}${l1}`,
    buildingNext: `${building}${l2}`,
    cost: `${cost}${l1}`,
    costNext: `${cost}${l2}`,
    purchase,
    operation,
    dh,
    expand,
    rent: `/rent/#`,
    rentNext: `/rent/next`,
    asset: '/asset/next',
    // park
    parkPlatform,
    // vision
    vision: `${vision}${l1}`,
    visionNext: `${vision}${l2}`,
};
// @ts-ignore
window.dworkPathnameMap = dworkPathnameMap;
const rent = `${boss}rent`;
const park = '/dwork/win/park/';
const pc = `${park}pc${l1}`;
const park_lowCode = `${park}park_lowCode${l2}`;
const nextPage = `${park}next/page`;
// 未升级的工程
const notUpgradePathname = [
    // boss
    {
        cone: ['/rent', '/rent/'],
        origin: ['cnstr', 'boss'],
        next: '/rent/next',
        pathname: rent,
        notUpgrade: true,
    },
    // park
    {
        cone: ['/park/pc', '/park/pc/'],
        origin: ['park'],
        pathname: pc,
        notUpgrade: true,
    },
    {
        cone: ['/park/park_lowCode', '/park/park_lowCode/'],
        origin: ['park'],
        pathname: park_lowCode,
        notUpgrade: true,
    },
    {
        origin: ['park'],
        next: '/park/next/page',
        pathname: nextPage,
        notUpgrade: true,
    },
    {
        cone: ['/lowcode', '/lowcode/'],
        origin: ['vision'],
        pathname: dworkPathnameMap.visionNext,
        notUpgrade: true,
    },
];
const formatPathname = [
    // boss
    {
        cone: ['/'],
        origin: ['cnstr', 'boss'],
        pathname: home,
        homePath: '/home',
    },
    {
        cone: ['/basic', '/basic/'],
        origin: ['cnstr', 'boss'],
        next: '/basic/next',
        pathname: basic,
    },
    {
        cone: ['/site', '/site/'],
        origin: ['cnstr', 'boss'],
        next: '/site/next',
        pathname: site,
    },
    {
        cone: ['/building', '/building/'],
        origin: ['cnstr', 'boss'],
        next: '/building/next',
        pathname: building,
    },
    {
        cone: ['/cost', '/cost/'],
        origin: ['cnstr', 'boss'],
        next: '/cost/next',
        pathname: cost,
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/purchase/next',
        pathname: purchase,
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/operation/next/page',
        pathname: operation,
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/dh/next',
        pathname: dh,
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/expand/next',
        pathname: expand,
    },
    // asset
    {
        origin: ['cnstr', 'boss', 'asset'],
        next: '/asset/next',
        pathname: asset,
        notUpgrade: true,
    },
    {
        origin: ['cnstr', 'boss', 'asset'],
        next: '/cp/asset/next',
        pathname: asset,
        notUpgrade: true,
    },
    // park
    {
        cone: ['/platform', '/platform/'],
        origin: ['park'],
        pathname: parkPlatform,
    },
    // vision
    {
        cone: ['/'],
        origin: ['vision'],
        next: '/cone/next',
        pathname: vision,
        homePath: '/home',
    },
    {
        cone: [
            '/inc/home',
            '/inc/home/',
            '/inc/guoshou',
            '/inc/guoshou/',
            '/data',
            '/data/',
        ],
        origin: ['vision'],
        pathname: dworkPathnameMap.vision,
    },
    // 未升级的工程
    ...notUpgradePathname,
];
let hs;
let cs;
const us = { other: [] };
let logs;
// 显示日志
const showLog = () => location.href.includes('log=true');
const log = () => {
    logs && clearTimeout(logs);
    logs = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('cone-container-custom-shell', hs);
        // eslint-disable-next-line no-console
        console.log('custom-get-menu', cs);
        // eslint-disable-next-line guard-for-in
        for (const key in us) {
            // 菜单去重
            if (us[key] instanceof Array) {
                us[key] = [...new Set(us[key])];
            }
            // 合并未升级的菜单
            if (notUpgradePathname.findIndex((ele) => key.includes(ele.pathname)) !== -1) {
                if (!us.notUpgrade) {
                    us.notUpgrade = {};
                }
                us.notUpgrade[key] = us[key];
                delete us[key];
            }
            // 合并接口升级过的菜单
            if (key === 'other') {
                const pathnames = formatPathname.map(({ pathname }) => pathname);
                if (!us.apiUpgrade) {
                    us.apiUpgrade = {};
                }
                us[key]?.forEach((url, i) => {
                    const index = pathnames.findIndex((p) => url.includes(p));
                    if (index !== -1) {
                        if (!us.apiUpgrade[pathnames[index]]) {
                            us.apiUpgrade[pathnames[index]] = [];
                        }
                        us.apiUpgrade[pathnames[index]].push(url);
                        delete us[key][i];
                    }
                });
                us[key] = us[key].filter((url) => url).sort();
            }
        }
        // 合并导航升级的菜单
        for (const k in us) {
            if (k.startsWith('/')) {
                if (!us.headerUpgrade) {
                    us.headerUpgrade = {};
                }
                us.headerUpgrade[k] = us[k];
                delete us[k];
            }
        }
        // eslint-disable-next-line no-console
        console.log('us', us);
    }, 5000);
};
if (showLog()) {
    log();
}
// 处理菜单url
export const handleFormatMenuUrl = (tree, key = 'menuUrl') => {
    let arr = tree;
    if (typeof tree === 'string') {
        arr = [{ [key]: tree }];
    }
    arr?.forEach((item, i) => {
        try {
            let handle = false;
            if (item[key]?.startsWith('/')) {
                // eslint-disable-next-line no-param-reassign, @typescript-eslint/restrict-plus-operands
                item[key] = location.origin + item[key];
            }
            if (item[key] && item[key].startsWith('http')) {
                const u = new URL(item[key]);
                formatPathname.forEach(({ cone, next, pathname, origin, notUpgrade, homePath }) => {
                    if (!origin ||
                        (origin.length &&
                            origin.findIndex((ele) => u.origin.includes(ele)) !== -1)) {
                        if ((cone && cone.includes(u.pathname)) ||
                            u.pathname === pathname) {
                            const hash = u.hash.replace('#', '').split('?');
                            u.hash = '';
                            if (hash[1]) {
                                u.search += u.search ? `&${hash[1]}` : `?${hash[1]}`;
                            }
                            const pe = `${pathname}${next ? l1 : ''}`;
                            u.pathname = `${pe}${hash[0] || ''}`;
                            if (!notUpgrade) {
                                if (u.pathname === pe && homePath) {
                                    u.pathname += homePath;
                                }
                                // eslint-disable-next-line no-param-reassign
                                item[key] = u.href;
                            }
                            if (typeof tree !== 'string') {
                                if (!us[pe])
                                    us[pe] = [];
                                us[pe].push(u.href);
                                handle = true;
                            }
                        }
                        else if (next && u.pathname.startsWith(next)) {
                            const pe = `${pathname}${cone ? l2 : ''}`;
                            u.pathname = u.pathname.replace(next, pe);
                            if (!notUpgrade) {
                                // eslint-disable-next-line no-param-reassign
                                item[key] = u.href;
                            }
                            if (typeof tree !== 'string') {
                                if (!us[pe])
                                    us[pe] = [];
                                us[pe].push(u.href);
                                handle = true;
                            }
                        }
                    }
                });
            }
            if (!handle && item[key]) {
                us.other.push(item[key]);
            }
            if (item.children?.length) {
                handleFormatMenuUrl(item.children);
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
        if (i === arr.length - 1) {
            if (showLog()) {
                log();
            }
        }
    });
    if (typeof tree === 'string') {
        return arr[0][key];
    }
};
// @ts-ignore
window.handleFormatMenuUrl = handleFormatMenuUrl;
// 自定义跳转
// @ts-ignore
window.customJump = (params) => {
    let pathname = params?.pathname || params;
    if (pathname?.startsWith('http')) {
        const u = new URL(pathname);
        if (u.origin === location.origin) {
            pathname = u.href.replace(u.origin, '');
        }
    }
    let index = 4;
    if (location.origin.includes('park')) {
        index = 1;
    }
    const siteCode = pathname.split('/')?.[index];
    const currentSiteCode = location.pathname.split('/')?.[index];
    // @ts-ignore
    if (siteCode === currentSiteCode && window.jumpTo) {
        if (params instanceof Object) {
            // eslint-disable-next-line no-param-reassign
            params.pathname = pathname;
        }
        // @ts-ignore
        window.jumpTo(params instanceof Object ? params : pathname);
    }
    else if (params?.type) {
        window.open(pathname);
    }
    else {
        location.href = pathname;
    }
};
// @ts-ignore
window.winJumpTo = window.customJump;
// 判断老页面跳转新页面
export const customJumpHref = (path) => {
    const p = path
        ? `${path.startsWith('http') ? '' : location.origin}${path}`
        : window.location.href;
    const currentHref = handleFormatMenuUrl(p);
    if (p !== currentHref) {
        if (path) {
            return currentHref;
        }
        window.location.href = currentHref;
    }
};
customJumpHref('');
function urlChangeListener() {
    const path = customJumpHref(document.location.href);
    if (path) {
        if (showLog()) {
            console.log('URL已变化:', document.location.href, path);
        }
        // @ts-ignore
        window.customJump(path);
    }
}
// 监听popstate事件
window.addEventListener('popstate', urlChangeListener);
// 手动处理pushState和replaceState
const originalPushState = history.pushState;
history.pushState = function () {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    originalPushState.apply(history, arguments);
    urlChangeListener();
};
const originalReplaceState = history.replaceState;
history.replaceState = function () {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    originalReplaceState.apply(history, arguments);
    urlChangeListener();
};
// 菜单请求
export const sendMenuRequest = async () => {
    try {
        const url = '/menu/getMenuByMember';
        let productCode = location.pathname.split('/')?.[3] || undefined;
        let index = 4;
        if (location.origin.includes('park')) {
            productCode = 'park';
            index = 1;
        }
        const siteCode = location.pathname.split('/')?.[index] || undefined;
        let res;
        try {
            res = await request({
                url,
                method: 'post',
                data: {
                    tenantCode: 'dwork',
                    appCode: 'win',
                    productCode,
                    siteCode,
                },
            });
        }
        catch (error) {
            res = await request({
                url: '/basic/menu/cn/getMenuByMember',
                method: 'post',
                data: {
                    tenantCode: 'dwork',
                    appCode: 'win',
                    productCode,
                    siteCode,
                },
            });
        }
        const { data = {}, errorCode, errorMsg, success } = res?.data || {};
        if (cnOptions.loginCallback(errorCode, errorMsg, res))
            return null;
        cs = data;
        if (!success) {
            CnMessage.error(errorMsg);
        }
        return data;
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
// 自定义菜单
export const customGetMenu = async () => {
    try {
        // 获取登录人信息
        await queryLoginInfo();
        return await sendMenuRequest();
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
// 自定义业务arms实例配置
export const customArmsConfig = (siteInfo) => {
    const customInfo = {
        // @ts-ignore
        uid: window._GATEWAY_PF?.userId,
        // @ts-ignore
        username: window._GATEWAY_PF?.userName,
        userType: 
        // @ts-ignore
        window._GATEWAY_PF &&
            // @ts-ignore
            (window._GATEWAY_PF?.enterpriseId === '25' ||
                // @ts-ignore
                window._GATEWAY_PF?.enterpriseId === '3')
            ? // @ts-ignore
                window.coneArms?.userTypeMap?.staff
            : // @ts-ignore
                window.coneArms?.userTypeMap?.cp,
        accountType: 
        // @ts-ignore
        window._GATEWAY_PF && window._GATEWAY_PF?.userId?.length < 12
            ? // @ts-ignore
                window.coneArms?.accountTypeMap?.BUC
            : '',
    };
    const info = {
        ...siteInfo,
        ...customInfo,
    };
    if (showLog()) {
        console.log('custom-arms-config', siteInfo, customInfo);
    }
    return info;
};
export function isMobileDevice() {
    const match = window.matchMedia(`(max-width: 768px)`);
    return match.matches;
}
// 自定义shell
export const coneContainerCustomShell = ({ children, headerProps }) => {
    // const { menu, menuOnSelect, menuSelectedKey } = headerProps; // 参照CnShell组件的headerProps属性中的这些字段的数据结果
    hs = headerProps;
    const isWin = location.origin.includes('win');
    const isPark = location.origin.includes('park');
    const isVision = location.origin.includes('vision');
    const menuCodeList = ['DWORK_CNSTR_GCGL_TASK', 'DWORK_CNSTR_COST_CALCULATE'];
    const visionMobile = location.origin.includes('vision') && isMobileDevice();
    // 不显示导航
    if (location.href.includes('hideNav=true') ||
        location.pathname.includes(`${dworkPathnameMap.home}/board`) ||
        visionMobile) {
        return children;
    }
    return (React.createElement(Index
    // @ts-ignore
    , { 
        // @ts-ignore
        noBoss: isWin || isPark || isVision, useHelp: isWin || isPark || isVision, sideBarMenuCodeList: menuCodeList, downloadCenterUrl: isPark ? `/park/pc/#/downloadCenter` : '' }, children));
};
// 自定义404界面
export const osNotFoundComponent = () => {
    return (React.createElement(CnBox, { justify: 'center', align: 'center', style: { height: '100%' } },
        React.createElement("div", { style: {
                width: 250,
                height: 250,
                marginTop: -60,
                background: "url('https://img.alicdn.com/imgextra/i2/O1CN01k0XTW31hHvGtZJBVJ_!!6000000004253-1-tps-500-500.gif') center center / 250px no-repeat",
                WebkitClipPath: 'polygon(0 0, 100% 0, 100% 90%, 0 90%)',
                clipPath: 'polygon(0 0, 100% 0, 100% 90%, 0 90%)',
            } })));
};
