/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  CnCard,
  CnDrawer,
  Field,
  Form,
  CnInputTextArea,
  CnBox,
  CnMessage,
  CnDatePickerPro,
  CnInput,
  CnAsyncSelect,
  CnIcon,
  Button,
} from '@cainiaofe/cn-ui';
import ChunkUpload from './chunk-upload';
import {
  update,
  useSceneBizId,
  useMajorBizId,
  useNodeDTOList,
} from '../../services';
import List from './list';
import {
  disabledDateBefore,
  // mobileRobotUrl,
  pcRobotUrl,
  validateField,
} from './utils';
import { isEn, locale } from '../../shared';
import { isMobile } from '..';
import { CnCol, CnRow } from '../../components/CnGrid';
import './index.scss';

export default function ({
  helpVisible,
  onClose,
  noBoss: isBoss,
  employeeId,
  currentPageProductCode,
}) {
  // 建设也下掉机器人
  const noBoss = true;
  // const robotUrl = isMobile ? mobileRobotUrl : pcRobotUrl;
  const robotUrl = pcRobotUrl;
  // 特殊值
  const field = useRef(Field.useField());
  const fieldValues = field.current.getValues();
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [sceneDataSource, defaultSceneBizId] = useSceneBizId(
    isBoss,
    currentPageProductCode,
  );
  const sceneBizId = useMemo(
    () => fieldValues.sceneBizId || defaultSceneBizId,
    [defaultSceneBizId, fieldValues.sceneBizId],
  );
  useEffect(() => {
    if (defaultSceneBizId) {
      field.current.setValues({ sceneBizId: defaultSceneBizId });
    }
  }, [defaultSceneBizId]);

  const [major, defaultMajorBizId] = useMajorBizId(sceneBizId);
  useEffect(() => {
    if (defaultMajorBizId) {
      field.current.setValues({ majorBizId: defaultMajorBizId });
    }
  }, [sceneBizId, defaultMajorBizId]);

  const [nodeDTOList, nodeLoading] = useNodeDTOList(
    true,
    sceneBizId,
    fieldValues.majorBizId,
  );

  // 机器人是否收起
  const [foldRobot, setFoldRobot] = useState(!!noBoss);
  // 机器人是否铺满抽屉
  const [fullRobot, setFullRobot] = useState(
    !noBoss ? window.location.search.includes('fullRobot=true') : false,
  );

  const [visible, setVisible] = useState();
  useEffect(() => {
    if (helpVisible && Array.isArray(helpVisible)) {
      setVisible(false);
      if (!noBoss) {
        setFoldRobot(true);
      }
      // eslint-disable-next-line react/prop-types
      field.current.setValues({ traceId: helpVisible?.join(';') || undefined });
    } else {
      if (!noBoss) {
        setFoldRobot(window.location.search.includes('foldRobot=true'));
      }
      field.current.setValues({ traceId: undefined });
    }
  }, [helpVisible, noBoss]);

  // 保存
  const submit = async () => {
    const values = await validateField(field.current);
    if (values) {
      if (nodeDTOList.length) {
        setLoading(true);
        update({
          ...values,
          attachmentIds: files,
          distributed: 1,
          sceneBizId,
          nodeDTOList,
          level: 'D',
        })
          .then((success) => {
            if (success) {
              setTimeout(() => {
                field.current.reset();
                setFiles([]);
                setVisible('isAwait');
              }, 500);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        CnMessage.error(locale.nodeDTOListEmpty);
      }
    }
  };

  return (
    <CnDrawer
      visible
      footer={foldRobot}
      headerStyle={{ height: 94 }}
      title={
        <CnBox
          direction='row'
          align='center'
          className='intelli-assistant-panel-hint'
          spacing={6}
        >
          <img
            src='https://img.alicdn.com/imgextra/i4/O1CN01Z4U8Jn1kQZbKo8p8r_!!6000000004678-2-tps-236-236.png'
            style={{ width: 46, height: 46 }}
          />
          <div className={`userName ${isEn ? 'en' : ''}`}>
            {foldRobot ? locale.estateAsst : locale.robotEstateAsst}
          </div>
          <div className='content'>
            {window?._GATEWAY_PF?.userName
              ? `${window?._GATEWAY_PF?.userName}${locale.dou}`
              : ''}
            {new Date().getHours() > 12 ? locale.afternoon : locale.morning}
            {foldRobot ? locale.pleaseTickets : locale.robotPleaseTickets}
          </div>
        </CnBox>
      }
      size='small'
      style={
        isMobile
          ? { maxWidth: '100%', width: '100%' }
          : { width: fullRobot ? 808 : 404 }
      }
      onCancel={onClose}
      onClose={onClose}
      onOk={submit}
      okProps={{
        children: locale.submit,
        loading,
        visible: true,
        disabled: uploading || nodeLoading,
      }}
      cancelProps={{ children: locale.cancel, visible: true }}
    >
      <CnBox onClick={() => setVisible(true)}>
        <CnCard
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            height: 96,
            background:
              'url(https://img.alicdn.com/imgextra/i1/O1CN01gTri8P1cW3PMtLUA8_!!6000000003607-0-tps-656-184.jpg) no-repeat 50%/cover',
          }}
        >
          <CnBox style={{ fontWeight: 500, color: '#05102d', fontSize: 16 }}>
            {locale.ticketCenter}
          </CnBox>
          <CnBox>{locale.viewTickets}</CnBox>
          {visible ? (
            <List
              visible={visible}
              onClose={() => {
                setVisible(false);
                onClose();
              }}
              employeeId={employeeId}
            />
          ) : null}
        </CnCard>
      </CnBox>
      <CnCard
        x-if={!noBoss}
        title={locale.robotTitle}
        foldable
        fold={foldRobot}
        onFoldChange={(f) => {
          setFullRobot(false);
          setFoldRobot(f);
        }}
        action={
          !foldRobot ? (
            <Button
              text
              type='secondary'
              onClick={() => setFullRobot(!fullRobot)}
            >
              <CnIcon type={fullRobot ? 'exit-fullscreen' : 'fullscreen'} />
            </Button>
          ) : null
        }
        style={
          fullRobot && !foldRobot
            ? {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }
            : {}
        }
      >
        <iframe
          src={robotUrl}
          style={{
            width: '100%',
            border: 0,
            height: `calc(100vh - ${fullRobot ? 92 : 409}px)`,
          }}
        />
      </CnCard>
      <CnCard
        loading={loading}
        title={locale.newTicket}
        foldable={!noBoss}
        fold={!foldRobot}
        onFoldChange={(f) => {
          setFullRobot(false);
          setFoldRobot(!f);
        }}
      >
        <Form
          fullWidth
          field={field.current}
          useLabelForErrorMessage
          className={
            isMobile &&
            !window.location.search.includes('__next_force_pc__=true')
              ? 'mobile-custom-form-item-label-font-size'
              : ''
          }
        >
          <CnRow wrap>
            <CnCol span={24}>
              <Form.Item label={locale.problemUrl} required name='url'>
                <CnInput
                  defaultValue={window.location.href}
                  placeholder={locale.problemUrlPlaceholder}
                />
              </Form.Item>
            </CnCol>
            <CnCol span={24}>
              <Form.Item label={locale.product} required name='sceneBizId'>
                <CnAsyncSelect
                  showSearch
                  dataSource={sceneDataSource}
                  onChange={() =>
                    field.current.setValues({ majorBizId: undefined })
                  }
                />
              </Form.Item>
            </CnCol>
            <CnCol span={16} style={{ paddingRight: 6 }}>
              <Form.Item label={locale.problemType} required name='majorBizId'>
                <CnAsyncSelect showSearch dataSource={major} />
              </Form.Item>
            </CnCol>
            <CnCol span={8} style={{ paddingLeft: 6 }}>
              <Form.Item label={locale.handler} required name='handlerName'>
                <CnInput
                  disabled
                  placeholder={locale.handler}
                  value={
                    nodeDTOList?.[0]?.roleDetailList
                      ?.map((ele) => ele.label)
                      ?.join(',') || nodeDTOList?.[0]?.operatorName
                  }
                />
              </Form.Item>
            </CnCol>
            <CnCol span={24}>
              <Form.Item
                label={locale.problemDescription}
                required
                name='content'
              >
                <CnInputTextArea
                  placeholder={locale.problemDescriptionPlaceholder}
                  maxLength={500}
                  showLimitHint
                  rows={noBoss ? 10 : 5}
                />
              </Form.Item>
            </CnCol>
            <CnCol span={24}>
              <Form.Item label={locale.traceId} name='traceId'>
                <CnInput placeholder={locale.traceIdPlaceholder} />
              </Form.Item>
            </CnCol>
            <CnCol span={24}>
              <ChunkUpload
                defaultValue={files}
                onChange={setFiles}
                label={locale.problemPhoto}
                labelAlign='top'
                name='attachmentIds'
                accept='.png,.jpg,.jepg'
                setUploading={setUploading}
                multiple
                paste
                type='img'
              />
            </CnCol>
            <CnCol span={24}>
              <Form.Item label={locale.deadline} name='deadline'>
                <CnDatePickerPro
                  style={{ width: '100%' }}
                  disabledDate={disabledDateBefore}
                />
              </Form.Item>
            </CnCol>
          </CnRow>
        </Form>
      </CnCard>
    </CnDrawer>
  );
}
